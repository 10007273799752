<template>

    <LwxBreadcrumb :pages="[{ name: state.id ? 'Edit Shift' : 'Add Shift', href: '/invoice/' + selectedPeriod + '/add-shift', current: true }]"></LwxBreadcrumb>

    <div class="w-full mx-auto pt-2 font-2xl font-bold text-lifeworx-green-500">

        <div v-if="state.client" class="py-2 hover:bg-green-100 rounded cursor-pointer md:px-4 flex flex-row" @click="resetClient()">
            <h4 class="grow">
                1. Client:&nbsp;&nbsp; {{ state.client.first_name + ' ' + state.client.last_name }}
            </h4>
            <div class="shrink justify-items-end">
                <PencilSquareIcon class="h-6 w-6" aria-hidden="true" />
            </div>
        </div>

        <div v-if="state.rate" class="py-2 hover:bg-green-100 rounded cursor-pointer md:px-4 flex flex-row" @click="resetRate()">
            <h4 class="grow">
                2. Rate:&nbsp;&nbsp; {{ getFormattedRateDisplayName(state.rate) }}
            </h4>
            <div class="shrink justify-items-end">
                <PencilSquareIcon class="h-6 w-6" aria-hidden="true" />
            </div>
        </div>

        <div v-if="state.shift" class="py-2 hover:bg-green-100 rounded cursor-pointer md:px-4 flex flex-row items-center" @click="resetShift()">
            <h4 class="grow hidden md:block">
                3. Start:&nbsp;&nbsp; {{ util.makeFriendlyDate(state.shift.start, config) }}<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;End:&nbsp;&nbsp; {{ util.makeFriendlyDate(state.shift.end, config) }}
            </h4>
            <h4 class="grow md:hidden">
                3. Start:&nbsp;&nbsp; {{ util.makeFriendlyShortDate(state.shift.start, config) }}<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;End:&nbsp;&nbsp; {{ util.makeFriendlyShortDate(state.shift.end, config) }}
            </h4>
            <div class="shrink justify-items-end ">
                <PencilSquareIcon class="h-6 w-6" aria-hidden="true" />
            </div>
        </div>

    </div>

    <div class="w-full mx-auto pt-2">

        <div v-if="!state.client">
            <h1 class="text-2xl font-bold text-lifeworx-blue-800 md:px-4">1. Select Client</h1>

            <div class="grid gap-4 grid-cols-1 w-full md:w-1/2 mx-auto pt-2 ">

                <template v-for="client in userStore.user.clients">

                    <LwxButton v-if="client.rates.some(rate => rate.active)" @click="selectClient(client)" variant="secondary">
                        {{ client.first_name + ' ' + client.last_name }}
                    </LwxButton>

                </template>

            </div>
        </div>

        <div v-if="state.client && !state.rate">
            <h1 class="text-2xl font-bold text-lifeworx-blue-800 md:px-4">2. Select a Rate</h1>

            <div class="grid gap-4 grid-cols-1 w-full md:w-1/2 mx-auto pt-2 ">

                <template v-for="rate in state.client.rates">

                    <LwxButton v-if="rate.active" @click="selectRate(rate)" variant="secondary">
                        {{ getFormattedRateDisplayName(rate) }}
                    </LwxButton>

                </template>

            </div>
        </div>

        <div v-if="state.client && state.rate && !state.shift">
            <h1 class="text-2xl font-bold text-lifeworx-blue-800 md:px-4">3. Enter Shift</h1>

            <div class="grid gap-4 grid-cols-1 w-full md:w-1/2 mx-auto pt-2 ">

                <div class="w-full">

                    <RadioGroup v-model="state.shiftStartDay" class="grid grid-cols-4 gap-2 md:gap-4 my-4 font-semibold select-none" ref="shiftStartDay" @change="state.shiftEndDay = null">
                        <RadioGroupLabel class="col-span-4 text-lifeworx-blue-900 font-bold capitalize" :class="{ 'text-lifeworx-red-500': state.careNoteErrors[index] }">
                            Start Day
                            ({{ moment(invoices[invoiceStore.selected].start_date, config.dateFormatJs).format('MM/DD') }}
                            - {{ moment(invoices[invoiceStore.selected].end_date, config.dateFormatJs).format('MM/DD') }})
                        </RadioGroupLabel>
                        <RadioGroupOption
                            v-for="(day, index) in dayOptions"
                            v-slot="{ checked, disabled }"
                            :value="index"
                            class="h-16 rounded items-center group"
                            :disabled="index > 6"
                        >
                            <div class="h-full w-full border-2 rounded flex items-center justify-center align-middle text-center uppercase" :class="{ 'text-lifeworx-red-500 border-lifeworx-red-500': state.careNoteErrors[index] }, (disabled ? 'text-gray-300 border-gray-300 cursor-not-allowed bg-white' : 'border-lifeworx-blue-500 text-lifeworx-blue-500 hover:bg-lifeworx-blue-500 hover:border-lifeworx-blue-500 hover:text-white cursor-pointer '), { 'text-white bg-lifeworx-blue-500': checked }" :title="day.name">
                                <span v-if="!checked">
                                    {{ day.display }}
                                </span>
                                <span v-else class="text-sm">
                                    {{ day.display }}<br>
                                    {{ moment(invoices[invoiceStore.selected].start_date, config.dateFormatJs).add(index, 'days').format('MM/DD') }}
                                </span>
                            </div>
                        </RadioGroupOption>

                    </RadioGroup>

                    <div>
                        <h3 class="font-bold my-2">Start Time</h3>
                    </div>

                    <div>

                        <input type="time" @dblclick="showPicker" v-model="state.shiftStartTime" class="relative w-full h-16 md:h-16 flex items-center justify-center flex-1 rounded text-base font-bold tracking-wider text-center uppercase cursor-text z-30 text-lifeworx-blue-500 border-2 border-lifeworx-blue-500 hover:bg-opacity-100 hover:bg-lifeworx-blue-500 hover:text-white placeholder:text-bg-lifeworx-blue-500" ref="shiftStartTime" placeholder="--:-- --" />

                    </div>

                </div>

                <div v-if="!dayOptions[state.shiftStartDay] || !state.shiftStartTime || (startDateTime && !moment(startDateTime.value).isValid())" class="text-center text-gray-400 font-bold italic">
                    Please select your shift start day and time.
                </div>

                <div class="w-full">

                    <RadioGroup v-model="state.shiftEndDay" class="grid grid-cols-4 gap-2 md:gap-4 my-4 font-semibold select-none" ref="shiftStartDay">
                        <RadioGroupLabel class="col-span-4 text-lifeworx-blue-900 y-2 font-bold capitalize" :class="{ 'text-lifeworx-red-500': state.careNoteErrors[index] }">
                            End Day
                            ({{ moment(invoices[invoiceStore.selected].start_date, config.dateFormatJs).format('MM/DD') }}
                            - {{ moment(invoices[invoiceStore.selected].end_date, config.dateFormatJs).add(1, 'days').format('MM/DD') }})
                        </RadioGroupLabel>
                        <RadioGroupOption
                            v-for="(day, index) in dayOptions"
                            v-slot="{ checked, disabled }"
                            :value="index"
                            class="h-16 rounded items-center group"
                            :disabled="state.shiftStartDay === null || state.shiftStartDay > index || state.shiftStartDay+1 < index"
                        >
                            <div class="h-full w-full border-2 rounded flex items-center justify-center align-middle text-center uppercase" :class="(checked ? 'text-white bg-lifeworx-blue-500' : ''), { 'text-lifeworx-red-500 border-lifeworx-red-500': state.careNoteErrors[index] }, (disabled ? 'text-gray-300 border-gray-300 cursor-not-allowed bg-white' : 'border-lifeworx-blue-500 text-lifeworx-blue-500 hover:bg-lifeworx-blue-500 hover:border-lifeworx-blue-500 hover:text-white cursor-pointer ')" :title="day.name">
                                <span v-if="!checked">
                                    {{ day.display }}
                                </span>
                                <span v-else class="text-sm">
                                    {{ day.display }}<br>
                                    {{ moment(invoices[invoiceStore.selected].start_date, config.dateFormatJs).add(index, 'days').format('MM/DD') }}
                                </span>
                            </div>
                        </RadioGroupOption>

                    </RadioGroup>

                    <div>
                        <h3 class="font-bold my-2">End Time</h3>
                    </div>

                    <div class="relative">

                        <input type="time" @dblclick="showPicker" v-model="state.shiftEndTime" class="relative bg-opacity-0 w-full h-16 md:h-16 flex items-center justify-center flex-1 rounded text-base font-bold tracking-wider text-center uppercase cursor-text z-30  text-lifeworx-blue-500 border-2 border-lifeworx-blue-500 hover:bg-opacity-100 hover:bg-lifeworx-blue-500 hover:text-white placeholder:text-lifeworx-blue-500" ref="shiftEndTime" placeholder="--:-- --" />

                    </div>

                </div>

                <div v-if="startDateTime && endDateTime && (validShift === true)" class="text-center text-lifeworx-blue-800 font-bold">
                    Shift length: {{ util.friendlyShiftLength(startDateTime, endDateTime) }}
                </div>

                <div v-if="!dayOptions[state.shiftEndDay] || !state.shiftEndTime || (endDateTime && !moment(endDateTime.value).isValid())" class="text-center text-gray-400 font-bold italic">
                    Please select your shift end day and time.
                </div>

                <div class="text-red-500 text-center font-bold py-2" v-if="validShift !== true && validShift !== false">
                    {{ validShift }}
                </div>

                <LwxButton @click="selectShift()" :disabled="!(validShift === true)">
                    {{ state.client.careNotes ? 'Continue' : 'Save Shift' }}
                </LwxButton>

            </div>
        </div>

        <div v-if="state.client && state.rate && state.shift && state.client.careNotes">
            <h1 class="text-2xl font-bold text-lifeworx-blue-800 md:px-4">4. Enter Care Notes</h1>

            <div class="grid gap-4 grid-cols-1 w-full mx-auto pt-2 ">

                <div>
                    <h3 class="font-bold text-center pt-4">Select the level of assistance provided:</h3>

                    <div class="grid gap-4 grid-cols-4 uppercase text-lifeworx-blue-500 font-bold text-center text-sm md:text-base sticky h-20 -mb-8 top-20 pt-4 bg-gradient-to-b from-white to-transparent via-white z-10">

                        <h4>Not Done</h4>
                        <h4>Light Assist</h4>
                        <h4>Moderate Assist</h4>
                        <h4>Total Assist</h4>

                    </div>

                    <div class="uppercase text-lifeworx-blue-500 font-bold text-center text-sm md:text-base">

                        <RadioGroup v-model="state.careNotes[index]" v-for="(el, index) in careQuestions" class="grid gap-x-2 md:gap-x-4 grid-cols-4 my-4" ref="carenoteRefs">
                            <RadioGroupLabel class="col-span-4 text-lifeworx-blue-900 text-left py-2 capitalize" :class="{ 'text-lifeworx-red-500': state.careNoteErrors[index] }">
                                {{ el.label }}
                            </RadioGroupLabel>
                            <RadioGroupOption v-slot="{ checked }" value="X" class="w-full h-16 rounded items-center">
                                <div :class="(checked ? 'text-white bg-lifeworx-blue-200' : 'text-lifeworx-blue-200'), { 'text-lifeworx-red-500 border-lifeworx-red-500': state.careNoteErrors[index] }" class="h-full w-full border-2 border-lifeworx-blue-200 rounded hover:bg-lifeworx-blue-200 hover:border-lifeworx-blue-200 hover:text-white cursor-pointer flex items-center justify-center align-middle text-center"><span>X</span></div>
                            </RadioGroupOption>
                            <RadioGroupOption v-slot="{ checked }" value="L" class="w-full h-16 rounded items-center">
                                <div :class="(checked ? 'text-white bg-lifeworx-blue-300' : 'text-lifeworx-blue-300'), { 'text-lifeworx-red-500 border-lifeworx-red-500': state.careNoteErrors[index] }" class="h-full w-full border-2 border-lifeworx-blue-300 rounded hover:bg-lifeworx-blue-300 hover:border-lifeworx-blue-300 hover:text-white cursor-pointer flex items-center justify-center align-middle text-center"><span>L</span></div>
                            </RadioGroupOption>
                            <RadioGroupOption v-slot="{ checked }" value="M" class="w-full h-16 rounded items-center">
                                <div :class="(checked ? 'text-white bg-lifeworx-blue-400' : 'text-lifeworx-blue-400'), { 'text-lifeworx-red-500 border-lifeworx-red-500': state.careNoteErrors[index] }" class="h-full w-full border-2 border-lifeworx-blue-400 rounded hover:bg-lifeworx-blue-400 hover:border-lifeworx-blue-400 hover:text-white cursor-pointer flex items-center justify-center align-middle text-center"><span>M</span></div>
                            </RadioGroupOption>
                            <RadioGroupOption v-slot="{ checked }" value="T" class="w-full h-16 rounded items-center">
                                <div :class="(checked ? 'text-white bg-lifeworx-blue-500' : 'text-lifeworx-blue-500'), { 'text-lifeworx-red-500 border-lifeworx-red-500': state.careNoteErrors[index] }" class="h-full w-full border-2 border-lifeworx-blue-500 rounded hover:bg-lifeworx-blue-500 hover:border-lifeworx-blue-500 hover:text-white cursor-pointer flex items-center justify-center align-middle text-center"><span>T</span></div>
                            </RadioGroupOption>

                            <template v-if="el.additional_text">
                                <div class="col-span-4 h-16 mt-2">
                                    <label :for="el.slug + '_text'" class="sr-only">
                                        {{ el.text_label }} (Optional)
                                    </label>

                                    <div class="relative h-full rounded border-2 border-lifeworx-blue-300 overflow-hidden text-lifeworx-blue-300" :class="{ 'border-gray-300': !state.careNotes[index] || state.careNotes[index] == 'X' }">

                                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <PencilSquareIcon class="h-6 w-6" :class="{ 'text-gray-300': !state.careNotes[index] || state.careNotes[index] == 'X' }" aria-hidden="true" />
                                        </div>

                                        <input v-on:keydown.capture.stop v-model="state.careNotesText[el.slug]" :name="el.slug + '_text'" :id="el.slug + '_text'" class="block w-full h-full border-0 py-1.5 pl-10 ring-0 ring-inset ring-lifeworx-blue-400 text-center outline-0 font-semibold focus:outline-lifeworx-blue-400 placeholder:italic placeholder:text-gray-400 disabled:border-gray-300 disabled:text-gray-300 disabled:placeholder:text-gray-300 " :maxlength=50 :placeholder="el.text_label + ' (Optional)'" :disabled="!state.careNotes[index] || state.careNotes[index] == 'X'">

                                    </div>

                                </div>
                            </template>

                        </RadioGroup>

                    </div>

                </div>

                <div class="w-full md:w-1/2 mx-auto pt-2">

                    <LwxButton @click="validateCareNotes()" :variant="careNotesComplete">
                        Save Shift &amp; Care Notes
                    </LwxButton>

                </div>

            </div>
        </div>


    </div>

    <div class="w-full mx-auto pt-2 md:px-4">

        <div v-if="!state.client && !state.rate" class="py-2">
            <h4 class="font-2xl font-bold text-gray-400">
                2. Select a Rate
            </h4>
        </div>

        <div v-if="!state.rate" class="py-2">
            <h4 class="font-2xl font-bold text-gray-400">
                3. Enter Shift
            </h4>
        </div>

        <div v-if="state.client && (state.client.careNotes || state.client.extendedCareNotes) && !state.shift" class="py-2">
            <h4 class="font-2xl font-bold text-gray-400">
                4. Enter Care Notes
            </h4>
        </div>

    </div>


    <div class="w-full md:w-1/2 mx-auto">

        <LwxHr class="my-8 md:my-6" />

        <div class="grid gap-4 pt-2">

            <LwxButton @click="router.push({ path: '/invoice/' + selectedPeriod })" variant="primaryRed">
                {{ state.id ? 'Cancel Edits' : 'Cancel' }}
            </LwxButton>

            <LwxButton v-if="state.id" @click="deleteShift()" variant="secondaryRed">
                Delete Shift
            </LwxButton>

        </div>

    </div>

</template>

<script setup>

    import moment from 'moment'

    import { reactive, computed, watch, onMounted, ref, onUpdated } from 'vue'
    import { useRouter } from 'vue-router'

    import { util } from '@/Helpers'

    import { useConfigStore, useUserStore, useInvoiceStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LwxButton from '@/Components/LwxButton.vue'
    import LwxHr from '@/Components/LwxHr.vue'
    import LwxBreadcrumb from '@/Components/LwxBreadcrumb.vue';

    import { PencilSquareIcon } from '@heroicons/vue/20/solid'

    import {
        RadioGroup,
        RadioGroupLabel,
        RadioGroupOption,
    } from '@headlessui/vue'

    const router = useRouter()

    const configStore = useConfigStore()
    const userStore = useUserStore()
    const invoiceStore = useInvoiceStore()

    const config = configStore.config
    const { user } = storeToRefs(userStore)
    const { invoices, selectedPeriod } = storeToRefs(invoiceStore)

    const emit = defineEmits(['saveData', 'deleteData', 'error'])

    const props = defineProps({
        period: {
            type: String
        },
        id: {
            type: String
        },
        userProfile: {
            type: Object,
        },
        currentPeriod: {
            type: Number,
        }
    })

    const dayOptions = ref([
        {
            name: 'Sunday',
            display: 'Sun'
        },
        {
            name: 'Monday',
            display: 'Mon'
        },
        {
            name: 'Tuesday',
            display: 'Tue'
        },
        {
            name: 'Wednesday',
            display: 'Wed'
        },
        {
            name: 'Thursday',
            display: 'Thu'
        },
        {
            name: 'Friday',
            display: 'Fri'
        },
        {
            name: 'Saturday',
            display: 'Sat'
        },
        {
            name: 'Sunday',
            display: 'Sun'
        },
    ])

    const state = reactive({
        client: null,
        rate: null,
        shiftStartDay: null,
        shiftStartTime: null,
        shiftEndDay: null,
        shiftEndTime: null,
        shift: null,
        careNotes: {},
        careNotesText: {},
        careNoteErrors: [],
        careNoteSchema: null,
        id: null
    })

    const carenoteRefs = ref([])
    const longTermCarenoteVersion = import.meta.env.VITE_LONG_TERM_CARENOTE_VERSION;
    const healthCareCarenoteVersion = import.meta.env.VITE_HEALTH_CARE_CARENOTE_VERSION;

    if (!invoiceStore.selected) {
        invoiceStore.selected = selectedPeriod.value
    }

    onMounted(() => {

        // Check for shift ID to edit
        if (props.id) {

            if (invoices.value && invoices.value[ invoiceStore.selected ] && invoices.value[ invoiceStore.selected ].shifts) {

                let editData = invoices.value[ invoiceStore.selected ].shifts.filter(function (el) {
                    return el.id == props.id;
                })

                if (editData[0]) {

                    const periodStart = moment(invoices.value[invoiceStore.selected].start_date, config.dateFormatJs)
                    const periodEnd = moment(invoices.value[invoiceStore.selected].end_date, config.dateFormatJs)

                    state.id = editData[0].id
                    state.client = user.value.clients[editData[0].client_id]
                    state.rate = user.value.rates[editData[0].rate_id]

                    state.shiftStartDay = moment(editData[0].start, config.dateTimeFormatJs).diff(periodStart, 'days')
                    state.shiftStartTime = moment(editData[0].start, config.dateTimeFormatJs).format('HH:mm')

                    state.shiftEndDay = moment(editData[0].end, config.dateTimeFormatJs).diff(periodStart, 'days')
                    state.shiftEndTime = moment(editData[0].end, config.dateTimeFormatJs).format('HH:mm')

                    if (editData[0].carenote_data) {
                        selectShift()
                        if ((state.client.careNotes == true && state.client.extendedCareNotes == false && (editData[0].carenote_definition.split('-')[0] !== 'hc')) || (state.client.careNotes && state.client.extendedCareNotes && (editData[0].carenote_definition.split('-')[0] === 'hc'))) {
                            state.careNotes = indexCareNotes(editData[0].carenote_data)
                        }
                    }

                } else {

                    emit('error', {
                        type: 'shift',
                        code: 404
                    })

                    router.push({ path: '/invoice/' + selectedPeriod.value })

                }

            } else {

                emit('error', {
                    type: 'shift',
                    code: 404
                })

                router.push({ path: '/invoice/' + selectedPeriod.value })

            }

        }

    })

    watch(() => user.value, (selected) => {
        if (user.value.clients && !state.client) {
            const activeClients = Object.values(user.value.clients).filter(el => el.active);
            if (activeClients.length === 1) {
                const activeClientIndex = activeClients.indexOf(activeClients[0]);
                state.client = activeClients[activeClientIndex];
            }
        }
    }, { immediate: true })

    watch(() => state.shiftStartDay, (selected, changed) => {
        if (selected !== changed && changed !== null) {
            state.shiftEndDay = null
        }
    })

    const startDateTime = computed(() => {
        if (state.shiftStartDay != null && state.shiftStartTime && moment(state.shiftStartTime, 'HH:mm').isValid()) {
            const date = moment(invoices.value[invoiceStore.selected].start_date, config.dateFormatJs).add(state.shiftStartDay, 'days')
            return moment(`${date.format(config.dateFormatJs)} ${state.shiftStartTime}`, `${config.dateFormatJs} HH:mm`).toDate()
        }

        return null
    })

    const endDateTime = computed(() => {
        if (state.shiftEndDay != null && state.shiftEndTime && moment(state.shiftEndTime, 'HH:mm').isValid()) {
            const date = moment(invoices.value[invoiceStore.selected].start_date, config.dateFormatJs).add(state.shiftEndDay, 'days')
            return moment(`${date.format(config.dateFormatJs)} ${state.shiftEndTime}`, `${config.dateFormatJs} HH:mm`).toDate()
        }
        return null
    })

    //
    const careQuestions = computed(() => {
        if (state.client && (state.client.careNotes || state.client.extendedCareNotes)) {
            let carenoteDefinition = `ltc-${longTermCarenoteVersion}`;
            if (state.client.extendedCareNotes) {
                carenoteDefinition = `hc-${healthCareCarenoteVersion}`;
            }

            const questionsList = config.carenoteSchemas[carenoteDefinition]
            state.careNoteSchema = carenoteDefinition
            return questionsList;
        }
    })

    // Validate shift entry
    const validShift = computed(() => {
        if (startDateTime.value !== null && endDateTime.value !== null) {
            const start = moment(startDateTime.value).unix();
            const end = moment(endDateTime.value).unix();
            if (start >= end) {
                return "Your shift must start before it ends.";
            }
            let dstChange = util.getDstChangePosition(moment(startDateTime.value), moment(endDateTime.value))
            if (dstChange > 0 && (end-start) > 90000) {
                return "Shift entries must not be longer than 25 hours. You can add an additional shift after saving.";
            } else if (dstChange < 0 && (end-start) > 82800) {
                return "Shift entries must not be longer than 23 hours. You can add an additional shift after saving.";
            } else if (dstChange == 0 && (end-start) > 86400) {
                return "Shift entries must not be longer than 24 hours. You can add an additional shift after saving.";
            }

            if (startDateTime.value && endDateTime.value && (!moment(startDateTime.value).isValid() || !moment(endDateTime.value).isValid())) {
                return "Please enter a valid shift start and end time.";
            }

            if (invoices.value[ invoiceStore.selected ].shifts && invoices.value[ invoiceStore.selected ].shifts.length > 0) {

                let conflict = false
                invoices.value[ invoiceStore.selected ].shifts.forEach(el => {
                    if (el.id !== state.id) {
                        const otherStart = moment(el.start, config.dateTimeFormatJs).unix();
                        const otherEnd = moment(el.end, config.dateTimeFormatJs).unix();
                        if ((start > otherStart && start < otherEnd) || (end > otherStart && end < otherEnd) || (otherStart > start && otherStart < end) || (otherEnd > start && otherEnd < end) || (start == otherStart) || (end == otherEnd)) {
                            conflict = true
                        }
                    }

                })

                if (conflict == true) {
                    return "Shifts must not overlap.";
                }

            }

            return true;

        } else {
            return false;
        }
    })

    // Format care notes object for storage
    function formatCareNotes(careNotes) {
        if (careNotes) {

            let formatted = {}
            careQuestions.value.forEach((el, index) => {
                if (state.careNotes[index]) {
                    formatted[el.slug] = state.careNotes[index].toString()
                    if (el.additional_text && state.careNotes[index].toString() !== 'X') {
                        formatted[el.slug + '_text'] = state.careNotesText[el.slug]
                    }
                }
            })
            return formatted;

        } else {
            return null;
        }
    }

    // Care notes data array used when loading a shift for editing
    function indexCareNotes(data) {
        let indexed = []
        careQuestions.value.forEach((el, index) => {
            const tag = el.tag
            indexed[index] = data[tag].selection
            if (el.additional_text) {
                state.careNotesText[el.slug] = data[tag].additional_text
            }
        })
        return indexed;
    }

    // Returns a string used by the submit button to "disable" it when form is incomplete
    const careNotesComplete = computed(() => {
        if (state.careNotes && careQuestions.value && (Object.keys(state.careNotes).length == careQuestions.value.length) && !Object.values(state.careNotes).includes(undefined)) {
            return 'primary';
        } else {
            return 'disabled';
        }
    })

    function getFormattedRateDisplayName(rate) {
        if (rate.period === 24) {
            return `${rate.display_name} (${util.currency(rate.amount*24)}/24hr shift)`;
        } else {
            return `${rate.display_name} (${util.currency(rate.amount)}/hr)`;
        }
    }

    // Select rate automatically if there is only one
    // Also, prepopulate care notes values for this client if they are available in the last 8 cycles
    watch(
        () => state.client,
        (client) => {
            if (state.client) {
                const activeRates = state.client.rates.filter(el => el.active);
                if (activeRates.length === 1) {
                    const activeRateIndex = state.client.rates.indexOf(activeRates[0]);
                    state.rate = state.client.rates[activeRateIndex];
                }
            }
            if (state.client && state.client.careNotes && (Object.keys(state.careNotes).length === 0)) {
                // Iterate backwards through last 8 invoices, including the current one
                for (var i = invoiceStore.selected; i >= invoiceStore.selected-8; --i) {
                    if (invoices.value[i] && invoices.value[i].shifts && invoices.value[i].shifts.length > 0) {
                        invoices.value[i].shifts.forEach((shift) => {
                            if (shift.client_id == state.client.id && shift.carenote_data && (Object.keys(state.careNotes).length === 0)) {
                                state.careNotes = indexCareNotes(shift.carenote_data)
                            }
                        });
                    }
                    if (Object.keys(state.careNotes).length > 0) {
                        break;
                    }
                }
            }
        }, { immediate: true }
    )

    // Handle errored care notes
    watch(
        () => state.careNotes,
        (newValue, oldValue) => {
            if (careQuestions.value) {
                careQuestions.value.forEach((el, index) => {
                    if (state.careNoteErrors[index] && state.careNotes[index]) {
                        state.careNoteErrors[index] = false
                    }
                })
            }
        },
        { deep: true }
    )

    // Step one: pick a client
    function selectClient(client) {
        state.client = client;
    }

    // Step two: pick a rate
    function selectRate(rate) {
        state.rate = rate;
    }

    function showPicker(event) {
        try {
            event.currentTarget.showPicker()
        } catch (error) {
            event.currentTarget.focus()
        }
    }

    // Step three: enter a shift (complete if no care notes)
    function selectShift() {
        state.shift = {
            start: startDateTime.value,
            end: endDateTime.value
        }

        if (!state.client.careNotes) {

            emit('saveData', {
                type: 'shift',
                period: selectedPeriod.value,
                id: state.id,
                data: {
                    client: state.client,
                    rate: state.rate,
                    shift: state.shift
                }
            })

            router.push({ path: '/invoice/' + selectedPeriod.value })

        }

    }

    // Validates care notes and scrolls to the first missing/incomplete ADL
    function validateCareNotes() {

        if ((Object.keys(state.careNotes).length < careQuestions.value.length) || Object.values(state.careNotes).includes(undefined)) {
            careQuestions.value.forEach((el, index) => {
                if (!state.careNotes[index]) {
                    state.careNoteErrors[index] = true
                } else {
                    state.careNoteErrors[index] = false
                }
            })

            const firstErrorIndex = state.careNoteErrors.findIndex(el => el == true)

            util.scrollToElement(carenoteRefs.value[firstErrorIndex].el.id)

        } else {
            selectCareNotes()
        }

    }

    // Step 4: enter care notes
    function selectCareNotes() {

        emit('saveData', {
            type: 'shift',
            period: selectedPeriod.value,
            id: state.id,
            data: {
                client: state.client,
                rate: state.rate,
                shift: state.shift,
                careNotes: formatCareNotes(state.careNotes),
                carenote_definition: state.careNoteSchema
            }
        })

        router.push({ path: '/invoice/' + selectedPeriod.value })

    }

    // Emit delete request
    function deleteShift() {

        emit('deleteData', {
            type: 'shift',
            period: selectedPeriod.value,
            id: state.id
        })

        router.push({ path: '/invoice/' + selectedPeriod.value })

    }

    // Return to client selection
    function resetClient() {
        state.rate = null;
        state.client = null;
        state.shift = null;
        state.careNotes = [];
    }

    // Return to rate selection
    function resetRate() {
        state.rate = null;
    }

    // Return to shift entry
    function resetShift() {
        state.shift = null;
    }

</script>
