<template>

    <main>

        <div class="sm:max-w-full md:w-10/12 mx-auto grid gap-2 my-6">

            <header class="mb-4">

                <p class="pl-2 text-md text-center font-serif font-semibold text-lifeworx-blue-700">
                    Your Personal Business Settings
                </p>

            </header>

            <section class="grid gap-8 md:mx-20">

                <p class="text-xl text-center font-serif font-semibold text-lifeworx-blue-700">
                    Independent Contractor <br class="md:hidden" /> Invoice Customization
                </p>

            </section>

            <section class="grid gap-3 md:mx-20">

                <div>
                    <label for="username" class="text-lifeworx-blue-500 font-bold capitalize ">Theme</label>
                    <LwxSelect v-model="selectedTheme" :options="themes"/>
                </div>
                <div>
                    <label for="fonts" class="text-lifeworx-blue-500 font-bold capitalize ">Font</label>
                    <LwxSelect v-model="selectedFont" :options="fonts" class="mb-4"/>
                </div>

                <div class="mb-5">
                    <p class="text-center text-gray-400 text-md mb-1">Preview</p>
                    <div class="grid border-2 border-dashed px-4 gap-4 border-gray-200 rounded" :style="{ fontFamily: selectedFont.id.replace('+', ' ') }">
                        <div class="grid grid-cols-2 gap-4 pt-4">
                            <div>
                                <p class="text-gray-500 text-sm">{{ user.profile.first_name + ' ' + user.profile.last_name }}</p>
                                <p class="text-gray-500 text-sm">{{ user.profile.address.street }}</p>
                                <p class="text-gray-500 text-sm">{{ user.profile.address.city }}, {{ user.profile.address.state }} {{ user.profile.address.zip }}</p>
                            </div>
                            <div class="text-right">
                                <p class="font-semibold">Invoice 75</p>
                                <p class="font-semibold">Period 278</p>
                            </div>
                        </div>
                        <div class="pb-2">
                            <img :src="baseUrl + `/assets/invoice-themes/${selectedTheme.configuration.art.value}.png`"/>
                        </div>
                        <div class="grid grid-cols-2 py-4 border-y-2 mb-4"
                            :style="{
                                    borderTopColor: selectedTheme.configuration.color_divider,
                                    borderBottomColor: selectedTheme.configuration.color_divider
                                }">
                            <div>
                                <p
                                    class="font-semibold pb-2"
                                    :style="{
                                        color: selectedTheme.configuration.color_divider
                                    }"
                                >Shifts</p>
                            </div>
                            <div class="text-right">
                                <p
                                    class="font-semibold"
                                    :style="{
                                        color: selectedTheme.configuration.color_divider
                                    }"
                                >$144.00</p>
                            </div>
                            <div class="col-span-2">
                                <p class="text-gray-500">Example Client</p>
                                <p class="text-gray-500">Monday 2/25 - Tuesday 2/26</p>
                                <p class="text-gray-500">8 Hours @ $18</p>
                            </div>
                        </div>
                    </div>
                </div>

                <LwxButton @click="handleSaveSettings">
                    Save Settings
                </LwxButton>
                <LwxButton @click="router.push({ path: '/' })" variant="secondaryRed">
                    Cancel
                </LwxButton>
            </section>
        </div>

    </main>

</template>

<script setup>

    import { useRouter } from 'vue-router'

    import '@/Helpers'

    import LwxButton from '@/Components/LwxButton.vue'
    import LwxSelect from '@/Components/LwxSelect.vue'
    import { useUserStore, useConfigStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import { ref, watch } from 'vue'

    const baseUrl = import.meta.env.VITE_STORAGE_PATH;

    const router = useRouter()
    const userStore = useUserStore()
    const configStore = useConfigStore()
    const { user } = storeToRefs(userStore)
    const { config } = storeToRefs(configStore)

    const fonts = ref([])
    const themes = config.value.invoice_pdf_theme.themes
    const selectedTheme = ref()
    const selectedFont = ref()

    function handleSaveSettings() {
        console.log({
            'inv_pdf_font' : selectedFont.value.id,
            'inv_pdf_theme_id' : selectedTheme.value.id
        })
        userStore.saveSettings({
            'inv_pdf_font' : selectedFont.value.id,
            'inv_pdf_theme_id' : selectedTheme.value.id
        })
    }

    function changeFont(font) {
      const fontLink = document.createElement('link');
      fontLink.href = `https://fonts.googleapis.com/css2?family=${font}:wght@200;300;400;500;600;700&display=swap`;
      fontLink.rel = 'stylesheet';

      document.head.appendChild(fontLink);
    }

    watch([user, config], ([userValue, configValues]) => {
        fonts.value = configValues.invoice_pdf_theme.fonts.map(item => ({
            id: item.id,
            name: item.name,
        }))

        const selectedFontIndex = fonts.value.findIndex(item => item.id === userValue.profile.invoice_pdf_setting.font)
        selectedFont.value = selectedFontIndex >= 0 ? fonts.value[selectedFontIndex] : fonts.value[0]

        const selectedThemeIndex = config.value.invoice_pdf_theme.themes.findIndex(item => item.id === userValue.profile.invoice_pdf_setting.theme_id)
        selectedTheme.value = selectedFontIndex >= 0 ? config.value.invoice_pdf_theme.themes[selectedThemeIndex] : config.value.invoice_pdf_theme.themes[0]
    }, { immediate: true })

    watch(selectedFont, (value) => {
        changeFont(value.id)
    }, { immediate: true })
</script>
